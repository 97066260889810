  <template>
  <div class="friend-list-tab mt-2">
    <tab-nav :pills="true" id="tab" class="nav nav-pills d-flex align-items-center justify-content-left friend-list-items p-0 mb-2" >
      <tab-nav-items liClass=" " :active="true" id="pills-groups" href="#groups" ariaControls="pills-groups" role="tab" :ariaSelected="true" title="My groups" />
      <tab-nav-items liClass=" " :active="false" id="pills-managed-groups" href="#managed-groups" ariaControls="pills-managed-groups" role="tab" :ariaSelected="false" title="Groups manage" />
    </tab-nav>
    <div class="tab-content">
      <tab-content-item :active="true" id="groups" aria-labelled-by="pills-groups">
        <div class="iq-card-body p-0">
          <div class="row">
            <div class="col-md-6 col-lg-12 mb-3" v-for="(fried,index1) in groups" :key="index1">
              <div class="iq-friendlist-block">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <a href="#">
                      <img :src="fried.img" alt="profile-img" class="img-fluid">
                    </a>
                    <div class="friend-info ml-3">
                      <h5>{{fried.name}}</h5>
                      <p class="mb-0">{{fried.friend}}</p>
                    </div>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="dropdown">
                   <span class="dropdown-toggle btn btn-secondary mr-2" id="dropdownMenuButton01" data-toggle="dropdown" aria-expanded="true" role="button">
                   <i class="ri-check-line mr-1 text-white font-size-16"></i> Friend
                   </span>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton01">
                        <a class="dropdown-item" href="#">Get Notification</a>
                        <a class="dropdown-item" href="#">Close Friend</a>
                        <a class="dropdown-item" href="#">Unfollow</a>
                        <a class="dropdown-item" href="#">Unfriend</a>
                        <a class="dropdown-item" href="#">Block</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab-content-item>
      <tab-content-item :active="false" id="managed-groups" aria-labelled-by="pills-managed-groups">
        <div class="iq-card-body p-0">
          <div class="row">
            <div class="col-md-6 col-lg-12 mb-3" v-for="(item,index) in groupsManaged" :key="index">
              <div class="iq-friendlist-block">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <a href="#">
                      <img :src="item.img" alt="profile-img" class="img-fluid">
                    </a>
                    <div class="friend-info ml-3">
                      <h5>{{item.name}}</h5>
                      <p class="mb-0">{{item.friend}}</p>
                    </div>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="dropdown">
                     <span class="dropdown-toggle btn btn-secondary mr-2" id="dropdownMenuButton31" data-toggle="dropdown" aria-expanded="true" role="button">
                     <i class="ri-check-line mr-1 text-white font-size-16"></i> Friend
                     </span>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton31">
                        <a class="dropdown-item" href="#">Get Notification</a>
                        <a class="dropdown-item" href="#">Close Friend</a>
                        <a class="dropdown-item" href="#">Unfollow</a>
                        <a class="dropdown-item" href="#">Unfriend</a>
                        <a class="dropdown-item" href="#">Block</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab-content-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileGroupsTab',
  data () {
    return {
      groups: [ ],
      groupsManaged: [ ]
    }
  }
}
</script>

<style scoped>

</style>
