<template>
    <div>
      <GuestProfile v-if="isGuestProfile" :user="isGuestProfile"/>
      <MyProfile v-else/>
    </div>
</template>

<script>
import { socialvue } from '@/config/pluginInit'
import MyProfile from '@/components/homeiz/profiles/MyProfile'
import GuestProfile from '@/components/homeiz/profiles/GuestProfile'
import { UserList as Users } from '@/FackApi/api/Users'

export default {
  name: 'ProfileView',
  components: { GuestProfile, MyProfile },
  inject: ['authUser'],
  mounted () {
    socialvue.index()
  },
  data () {
    return {}
  },
  methods: { },
  computed: {
    isGuestProfile () {
      if (this.$route.params.id) {
        if (this.authUser.id !== parseInt(this.$route.params.id)) {
          return Users[this.$route.params.id]
        }
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
