<template>
  <b-col sm="12">
    <iq-card class="iq-card">
      <div class="iq-card-body p-0">
        <div class="user-tabing p-2">
          <div class="d-flex align-items-center justify-content-between">
            <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-forum-items text-center p-0 m-0 col-sm-12">
              <tab-nav-items class="col-sm-2 p-0" :active="true" id="pills-feed-tab" href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="true" title="Posts" />
              <tab-nav-items class="col-sm-2 p-0" :active="false" id="pills-activity-tab" href="#profile-reviews" ariaControls="pills-reviews" role="tab" :ariaSelected="false" title="Reviews" />
              <tab-nav-items class="col-sm-2 p-0" :active="false" id="pills-video-tab" href="#profile-videos" ariaControls="pills-videos" role="tab" :ariaSelected="false" title="Videos" />
              <tab-nav-items class="col-sm-2 p-0" :active="false" id="pills-photo-tab" href="#profile-photos" ariaControls="pills-photos" role="tab" :ariaSelected="false" title="Photos" />
              <tab-nav-items class="col-sm-2 p-0" :active="false" id="pills-connections-tab" href="#profile-connections" ariaControls="pills-friends" role="tab" :ariaSelected="false" title="Connections" />
              <tab-nav-items v-if="showActions" class="col-sm-2 p-0" :active="false" id="pills-more-tab" role="tab" :ariaSelected="false">
                <template v-slot:title>
                  <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0" class="profile-tabs-actions">
                    <template v-slot:button-content>
                      <b-link href="javascript:void(0)" class="fix-active">More <i class="ri-arrow-down-s-fill"></i></b-link>
                    </template>
                    <tab-nav :pills="true" id="pills-tab-more" class="nav nav-pills d-flex align-items-center justify-content-center profile-forum-items text-center p-0 m-0 col-sm-12">
                      <tab-nav-items class="dropdown-item" :active="false" id="pills-likes" href="#profile-likes" ariaControls="pills-likes" role="tab" :ariaSelected="false" title="Likes"></tab-nav-items>
                      <tab-nav-items class="dropdown-item" :active="false" id="pills-listings-tab" href="#profile-listings" ariaControls="pills-listings" role="tab" :ariaSelected="false" title="Listings"></tab-nav-items>
                      <tab-nav-items class="dropdown-item" :active="false" id="pills-stories-tab" href="#profile-stories" ariaControls="pills-stories" role="tab" :ariaSelected="false" title="Stories / Articles"></tab-nav-items>
                      <tab-nav-items class="dropdown-item" :active="false" id="pills-recommendations-tab" href="#profile-recommendations" ariaControls="pills-recommendations" role="tab" :ariaSelected="false" title="Recommendations"></tab-nav-items>
                      <tab-nav-items class="dropdown-item" :active="false" id="pills-groups" href="#profile-groups" ariaControls="pills-groups" role="tab" :ariaSelected="false" title="Groups"></tab-nav-items>
                    </tab-nav>
                  </b-dropdown>
                </template>
              </tab-nav-items>
            </tab-nav>
<!--            <div v-if="showActions" class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-end col-sm-4">-->
<!--              <button type="submit" class="btn btn-facebook mb-2 ml-1">Company page</button>-->
<!--              <button aria-label="Message" type="submit" class="btn btn-homeiz mb-2 ml-1"><i class="ri-send-plane-line"></i></button>-->
<!--              <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0" class="profile-tabs-actions mb-2 ml-1 btn btn-facebook">-->
<!--                <template v-slot:button-content>-->
<!--                  <b-link href="javascript:void(0)" class="text-secondary"><i-->
<!--                    class="mr-0 ri-more-2-line"></i></b-link>-->
<!--                </template>-->
<!--                <a href="javascript:void(0)" class="dropdown-item p-3">-->
<!--                  <div class="d-flex align-items-top">-->
<!--                    <div class="icon font-size-20"><i class="ri-close-circle-line"></i></div>-->
<!--                    <div class="data ml-2">-->
<!--                      <h6>Block</h6>-->
<!--                      <p class="mb-0">Block this user</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </b-dropdown>-->
<!--&lt;!&ndash;              <button type="submit" class="btn btn-facebook mb-2 ml-1">...</button>&ndash;&gt;-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </iq-card>
  </b-col>
</template>

<script>
export default {
  name: 'ProfileTabs',
  props: {
    showActions: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
