<template>
  <div>
    <iq-card v-if="!post.is_hidden" body-class="p-0">
        <template v-slot:body>
            <div class="user-post-data p-3">
              <div class="d-flex justify-content-between">
                <PostUserInfo
                  :post="post"
                  :user="post.user"
                  :notification-button-component="notificationButton"
                  :user-popover-component="userPopover"
                />
                <div class="d-flex">
                  <PostActions :post="post" />
                </div>
              </div>
            </div>
        </template>
        <hr class="m-0"/>
        <component
          class="p-3"
          v-bind:is="postContent"
          :post="post"
        />
        <FeedPostComments :hideComments="hideComments" :post="post"/>
    </iq-card>
    <iq-card v-else body-class="p-1">
      <template v-slot:body>
        <div class="d-flex justify-content-between align-items-center m-2">
          <div class="text-bold">Post hidden</div>
          <b-button variant="facebook" @click="hidePost">Undo</b-button>
        </div>
      </template>
    </iq-card>
  </div>
</template>
<script>
import * as postTypes from '@/config/post/postType'
import PostNotificationButton from '@/components/homeiz/feed/post/PostNotificationButton'
import PostUserPopover from '@/components/homeiz/user/UserPopover'
import PostUserInfo from '@/components/homeiz/feed/post/PostUserInfo'
import PostActions from '@/components/homeiz/feed/post/PostActions'
import PostContent from '@/components/homeiz/feed/post/type/PostContent'
import PollContent from '@/components/homeiz/feed/post/type/PollContent'
import PropertyContent from '@/components/homeiz/feed/post/type/PropertyContent'
import FeedPostComments from '@/components/homeiz/feed/FeedPostComments'

export default {
  name: 'FeedPost',
  components: { FeedPostComments, PostUserInfo, PostActions },
  props: {
    post: {
      type: Object,
      required: true
    },
    hideComments: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notificationButton: PostNotificationButton,
      userPopover: PostUserPopover
    }
  },
  computed: {
    postContent () {
      const typeMap = {
        [postTypes.POST]: PostContent,
        [postTypes.POLL]: PollContent,
        [postTypes.PROPERTY]: PropertyContent
      }

      return typeMap[this.post.type]
    }
  },
  methods: {
    hidePost () {
      this.post.is_hidden = !this.post.is_hidden
    }
  }
}
</script>
