<template>
  <tab-content>
    <tab-content-item :active="true" id="profile-feed">
      <AddPost @addPost="addPost"></AddPost>
      <div v-for="post in posts" :key="post.id">
        <SocialPost :post="post"></SocialPost>
      </div>
      <div class="col-sm-12 text-center">
        <button class="btn btn-primary mb-3" type="button">Load more</button>
      </div>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-reviews">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Reviews</h2>
        </template>
        <template v-slot:body>
          <ProfileReviewsTab/>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-videos">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Videos</h2>
        </template>
        <template v-slot:body>
          <div class="tab-content">
            <tab-content-item :active="true" id="video" aria-labelled-by="pills-photo-you-tab">
              <ProfileVideoTab/>
            </tab-content-item>
          </div>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-photos">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Photos</h2>
        </template>
        <template v-slot:body>
          <div class="tab-content">
            <tab-content-item :active="true" id="photo-you" aria-labelled-by="pills-photo-you-tab">
              <ProfilePhotoTab/>
            </tab-content-item>
          </div>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-connections">
      <ProfileConnectionsTab :admin-view="isAdmin"/>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-likes">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Likes</h2>
        </template>
        <template v-slot:body>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-listings">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Listing</h2>
        </template>
        <template v-slot:body>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-stories">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Stories / Articles</h2>
        </template>
        <template v-slot:body>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-recommendations">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Recommendations</h2>
        </template>
        <template v-slot:body>
        </template>
      </iq-card>
    </tab-content-item>
    <tab-content-item :active="false" id="profile-groups">
      <iq-card>
        <template v-slot:headerTitle>
          <h2>Groups</h2>
        </template>
        <template v-slot:body>
          <ProfileGroupsTab/>
        </template>
      </iq-card>
    </tab-content-item>
  </tab-content>
</template>

<script>
import SocialPost from '@/components/homeiz/feed/FeedPost'
import AddPost from '@/components/homeiz/feed/AddPost'
import { Posts } from '@/FackApi/api/SocialPost'
import ProfileConnectionsTab from './ProfileConnectionsTab'
import ProfileReviewsTab from './ProfileReviewsTab'
import ProfileVideoTab from './ProfileVideoTab'
import ProfilePhotoTab from './ProfilePhotoTab'
import ProfileGroupsTab from './ProfileGroupsTab'

export default {
  name: 'ProfilesTabsContent',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProfileGroupsTab,
    ProfilePhotoTab,
    ProfileVideoTab,
    ProfileReviewsTab,
    ProfileConnectionsTab,
    SocialPost,
    AddPost
  },
  data () {
    return {
      posts: Posts
    }
  },
  methods: {
    addPost (post) {
      this.posts.unshift(post)
    }
  }
}
</script>

<style scoped>

</style>
