<template>
  <div>
    <div to="rightSidebar">
      <ProfileMenu title='Profile' />
    </div>
    <b-row>
    <ProfileHeader :user="user" :editable="true" :show-actions="true"/>
    <ProfileTabs :user="user"/>
    <b-col sm="4">
      <BusinessConnectionWidget/>
      <FriendsWidget/>
    </b-col>
    <b-col sm="8">
      <tab-content>
        <ProfilesTabsContent :is-admin="true"/>
      </tab-content>
    </b-col>
    </b-row>
  </div>
</template>

<script>
import ProfileMenu from '@/components/homeiz/profiles/ProfileMenu'
import { mapGetters } from 'vuex'
import * as authGetters from '@/store/modules/auth/types/getters'
import ProfileHeader from './ProfileHeader'
import ProfileTabs from './ProfileTabs'
import ProfilesTabsContent from './ProfilesTabsContent'
import FriendsWidget from '@/components/homeiz/widgets/FriendsWidget'
import BusinessConnectionWidget from '@/components/homeiz/widgets/BusinessConectionWidget'

export default {
  name: 'MyProfile',
  components: {
    BusinessConnectionWidget,
    FriendsWidget,
    ProfilesTabsContent,
    ProfileMenu,
    ProfileHeader,
    ProfileTabs
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters('auth', {
      user: authGetters.GET_AUTH_USER
    })
  }
}
</script>

<style scoped>

</style>
