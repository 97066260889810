<template>
  <div v-if="isLoaded">
    <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
      <div class="iq-card-body">
        <div class="d-flex align-items-center" v-b-modal.post-modal>
          <div class="user-img">
            <img class="avatar-40 rounded-circle" :src="postAuthor.avatar" :alt="postAuthor">
          </div>
          <form class="post-text ml-2 w-100">
            <input type="text" :placeholder="placeholderText"
                   class="form-control add-post-text cursor-pointer" v-model="post.description" style="border:none;"/>
          </form>
        </div>
        <hr/>
        <ul class="post-opt-block d-flex align-items-center justify-content-between list-inline m-0 p-0 text-royal-blue">
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Photo" v-b-modal.post-modal>
            <!-- <i class="ri-image-line post-icon"></i> -->
            <img src="@/assets/images/icon/photo.svg" alt="photo" class="img-fluid svg">
            <!-- <div class="ml-1">Photo</div> -->
          </li>
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Video" v-b-modal.post-modal>
            <!-- <i class="ri-vidicon-line post-icon"></i> -->
            <img src="@/assets/images/icon/vids.svg" alt="video" class="img-fluid svg">
            <!-- <div class="ml-1">Vids</div> -->
          </li>
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Schedule" v-b-modal.schedule-modal>
            <img src="@/assets/images/icon/schedule.svg" alt="schedule" class="img-fluid svg">
            <!-- <i class="ri-calendar-event-line post-icon"></i> -->
            <!-- <div class="ml-1">Schedule</div> -->
          </li>
          <!-- <li class="rounded p-2 pointer cursor-pointer mr-3"><a href="#"></a><img
            src="@/assets/images/small/file.svg" alt="file" class="img-fluid svg"> File
          </li> -->
          <!-- <li class="rounded p-2 pointer cursor-pointer mr-3"><a href="#"></a><img
            src="@/assets/images/small/listing.svg" alt="listing" class="img-fluid svg"> Listing
          </li> -->
          <!-- <li class="rounded p-2 pointer cursor-pointer"><a href="#"></a><img
            src="@/assets/images/small/feeling.svg" alt="feeleing" class="img-fluid svg"> Feeling
          </li> -->
        </ul>
      </div>
      <b-modal
        id="post-modal"
        title="Create Post"
        size="lg"
        hide-footer
        content-class="rounded"
        no-fade
        no-close-on-backdrop
        no-close-on-esc
        @close="onCloseHandler"
      >
        <div class="d-flex">
          <div class="d-flex mr-2">
            <div class="media-support-user-img mr-3">
              <b-img rounded="circle" fluid :src="authUser.avatar" :alt="authUser.fullName" />
            </div>
            <div class="media-support-info">
              <h5 class="mb-0 d-flex align-items-center">
                {{ authUser.fullName }}
                <span class="user-location d-flex align-items-center">
                  <div v-if="authUser.isBusinessOwner" title="Business account">
                    <img src="@/assets/images/icon/business-owner.svg" class="img-fluid svg-30" />
                  </div>
                </span>
              </h5>
              <h6>{{ authUser.role }}</h6>
            </div>
          </div>
          <div class="d-flex">
            <div class="iq-card-post-toolbar">
              <AddPostStatusModal/>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <form class="post-text w-100">
            <b-form-textarea
              class="post-text-area"
              v-model="post.description"
              :placeholder="placeholderText"
              autofocus
              rows=11
              max-rows=12
              no-auto-shrink
              no-resize
              style="border:none;"
            >
            </b-form-textarea>
          </form>
        </div>

        <div v-if="post.files.length" class="d-flex w-100 mt-2">
          <PostFiles :files="post.files" />
        </div>
        <div v-if="post.videos.length" class="d-flex w-100 mt-2">
          <PostVideos v-if="videoLoaded" :videos="post.videos" />
        </div>
        <div v-if="post.images.length" class="d-flex w-100 position-relative mt-2">
          <PostPhotos  :images="postImages" />
          <b-button class="position-absolute remove-photos" variant="primary" @click="clearPhotos">X</b-button>
        </div>
        <div class="text-bold mt-4">Add to your post</div>
        <ul class="d-flex justify-content-around align-items-center list-inline m-0 p-0 mt-2 add-media">
          <li>
            <FileUploader
              :files="post.images"
              title="Select files to begin"
              alt="Photo"
              subTitle="Share images in your post"
              description="(Up to 15 Photos)"
              accept="image/*"
              :limit="15"
              :size-limit="fileSizes.image"
              @onFilesUpdate="updatePhoto"
            />
          </li>
          <li>
            <FileUploader
              :files="post.videos"
              image="video-uploader.jpg"
              icon="vids.svg"
              title="Select video file"
              alt="Video"
              subTitle="Share video in your post"
              accept="video/*"
              :limit="1"
              :size-limit="fileSizes.video"
              @onFilesUpdate="updateVideo"
            >
              <template v-slot:description>
                <div>MP4, WebM or OGG</div>
                <div>Up to 30 minutes</div>
                <div>Less than 2 GB</div>
              </template>
            </FileUploader>
          </li>
          <li>
            <FileUploader
              :files="post.files"
              image="file-uploader.png"
              icon="attachment.svg"
              title="Select file"
              alt="File"
              subTitle="Accepted files PDF, DOC"
              description="(Up to 5 files)"
              accept="application/pdf, application/msword"
              :limit="5"
              :size-limit="fileSizes.document"
              @onFilesUpdate="updateFile"
            />
          </li>
          <li v-for="(item,index) in tab" :key="index">
            <div class="rounded p-2 pointer cursor-pointer text-center" :title="item.name">
              <a href="#"></a>
              <img :src="renderIco(item.icon)" :alt="item.name" class="img-fluid svg">
              <!-- <i class="post-icon" :class="item.icon"></i> -->
              <!-- {{ item.name }} -->
            </div>
          </li>
        </ul>
        <button class="btn btn-primary d-block w-100 mt-3" :disabled="disableButton" @click="addNewPost(post)">Post</button>
      </b-modal>
      <b-modal id="schedule-modal" size="md" title="Schedule Your Post" hide-footer content-class="rounded" no-fade>
        <SchedulePost @schedulePost="schedulePost" />
      </b-modal>
    </iq-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import * as feedGetters from '@/store/modules/feed/types/getters'
import Post from '@/Model/Post'
import AddPostStatusModal from '@/components/homeiz/feed/AddPostStatusModal'
import PostPhotos from '@/components/homeiz/feed/post/PostPhotos'
import PostFiles from '@/components/homeiz/feed/post/PostFiles'
import FileUploader from '@/components/homeiz/common/form/FileUploaderLinkedin'
import SchedulePost from '@/components/homeiz/feed/SchedulePost'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'
import PostVideos from './post/PostVideos.vue'
import { MAX_DOCUMENT_FILE_SIZE, MAX_VIDEO_FILE_SIZE, MAX_IMAGE_FILE_SIZE } from '@/config/file.js'

export default {
  name: 'AddPost',
  components: {
    FileUploader,
    AddPostStatusModal,
    PostPhotos,
    PostFiles,
    SchedulePost,
    PostVideos
  },
  inject: ['authUser'],
  props: {
    author: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  created () {
    this.onLoad()
  },
  data () {
    return {
      fileSizes: {
        image: MAX_IMAGE_FILE_SIZE,
        video: MAX_VIDEO_FILE_SIZE,
        document: MAX_DOCUMENT_FILE_SIZE
      },
      isLoaded: false,
      videoLoaded: false,
      post: {},
      tab: [
        // {
        //   icon: '/icon/hashtag.svg',
        //   name: ' Hashtag'
        // },
        {
          icon: '/icon/gif.svg',
          name: ' GIF'
        },
        {
          icon: '/icon/feeling.svg',
          name: ' Emoji'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('feed', {
      draftPost: feedGetters.GET_DRAFT_POST
    }),
    postAuthor () {
      return this.author === null ? this.authUser : this.author
    },
    fullName () {
      return `${this.authUser.firstName} ${this.authUser.lastName}`
    },
    placeholderText () {
      return this.placeholder ? this.placeholder : `What's new, ${this.postAuthor.firstName}?`
    },
    disableButton () {
      return !(this.post.description || this.post.images.length || this.post.files.length || this.post.videos.length)
    },
    postImages () {
      if (this.post.images.length) {
        return this.post.images.map(image => {
          return image.preview
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    async onLoad () {
      if (this.draftPost) {
        this.post = new Post({ ...this.draftPost, user: this.authUser })
      } else {
        this.post = new Post(
          { user: this.authUser }
        )
      }
      this.isLoaded = true
    },
    async onCloseHandler (bvModalEvent) {
      if (!this.disableButton) {
        bvModalEvent.preventDefault()
        const response = await this.$bvModal.msgBoxConfirm(
          'The post you started will be here when you return.',
          {
            title: 'Save this post as a draft?',
            okTitle: 'Save as draft',
            cancelTitle: 'Discard',
            cancelVariant: 'transparent'
          })
        if (response) {
          this.$emit('saveDraft', this.post)
        } else {
          this.resetPost()
          this.$emit('discardPost')
        }
        this.$bvModal.hide('post-modal')
      }
    },
    addNewPost (post) {
      this.$emit('addPost', post)
      this.post = new Post({ user: this.authUser })
      this.$bvModal.hide('post-modal')
    },
    resetPost () {
      this.post = new Post(
        { user: this.authUser }
      )
    },
    previewImage: function (event) {
      const files = event.target.files
      Object.keys(files).forEach(i => {
        const file = files[i]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.post.images.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    updatePhoto (data) {
      if (data.length) {
        this.post.images = data
        // this.post.images = data.map(image => {
        //   return image.preview
        // })
      } else {
        this.post.images = []
      }
    },
    clearPhotos () {
      this.post.images = []
    },
    updateVideo (data) {
      this.post.videos = data
      this.videoLoaded = true
    },
    updateFile (data) {
      this.post.files = data
    },
    renderIco (ico) {
      return require(`@/assets/images${ico}`)
    },
    schedulePost (form) {
      this.setDoneNotification(
        {
          header: 'It\'s scheduled.',
          text: `Your post was successfully scheduled. It will be posted on ${moment(String(form.date)).format('dddd, MMMM Do')} at ${form.time.hh}:${form.time.mm} ${form.time.A}`
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  hr {
    margin: 0.6rem 0 0.2rem 0;
  }

  .iq-card-body {
    padding-bottom: 10px !important;
  }

  .modal-dialog {
    max-width: 800px !important;
  }

  .post-icon {
    font-size: 22px;
  }

  .remove-photos {
    right: 7px;
    top: 7px;
  }
</style>
