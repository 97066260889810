<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
        <div class="iq-card-body">
          <div class="d-flex align-items-center">
            <div class="user-image mb-3">
              <img class="avatar-80 rounded" src="@/assets/images/user/01.jpg" alt="#" data-original-title="" title="">
            </div>
            <div class="ml-3">
              <h5>Gurt Mistrioty</h5>
              <p>Web Developer</p>
            </div>
          </div>
          <div class="blog-description">
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                             </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
        <div class="iq-card-body">
          <div class="d-flex align-items-center">
            <div class="user-image mb-3">
              <img class="avatar-80 rounded" src="@/assets/images/user/02.jpg" alt="#" data-original-title="" title="">
            </div>
            <div class="ml-3">
              <h5>Tom Cruise</h5>
              <p>Web Designer</p>
            </div>
          </div>
          <div class="blog-description">
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum Many desktop publishing packages and web page editors now use Lorem Ipsum.</p>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                             </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
        <div class="iq-card-body">
          <div class="d-flex align-items-center">
            <div class="user-image mb-3">
              <img class="avatar-80 rounded" src="@/assets/images/user/03.jpg" alt="#" data-original-title="" title="">
            </div>
            <div class="ml-3">
              <h5>Walter Hucko</h5>
              <p>Web Designer</p>
            </div>
          </div>
          <div class="blog-description">
            <p>TThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                                <i class="fa fa-star-o"></i>
                                             </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
        <div class="iq-card-body">
          <div class="d-flex align-items-center">
            <div class="user-image mb-3">
              <img class="avatar-80 rounded" src="@/assets/images/user/04.jpg" alt="#" data-original-title="" title="">
            </div>
            <div class="ml-3">
              <h5>Tom Cruise</h5>
              <p>Web Manager</p>
            </div>
          </div>
          <div class="blog-description">
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.randomised words which don't look even slightly believable variations of passages.</p>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                             </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="iq-card-title">Your Review</h4>
          </div>
        </div>
        <div class="iq-card-body">
          <form>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Review</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
            </div>
            <button type="submit" class="btn btn-primary mr-3">Submit</button>
            <button type="submit" class="btn iq-bg-danger">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileReviewsTab'
}
</script>

<style scoped>

</style>
