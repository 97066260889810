<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h2 class="text-bold">{{ activeTab }}</h2>
    </template>
    <template v-slot:body>
      <div class="friend-list-tab mt-2">
        <tab-nav :pills="true" id="tab" class="nav nav-pills d-flex align-items-center justify-content-left friend-list-items p-0 mb-2" >
          <tab-nav-items liClass=" " v-on:click.native="updateTitle"  :active="true" id="pills-friends" href="#friends" ariaControls="pills-friends" role="tab" :ariaSelected="true" title="Friends" />
          <tab-nav-items liClass=" " v-on:click.native="updateTitle"  :active="false" id="pills-business" href="#business" ariaControls="pills-business" role="tab" :ariaSelected="false" title="Business" />
          <tab-nav-items liClass=" " v-on:click.native="updateTitleNeighbor"  :active="false" id="pills-neighborhood"  href="#neighborhood" ariaControls="pills-neighborhood" role="tab" :ariaSelected="false" title="Neighborhood" />
          <tab-nav-items liClass=" " v-on:click.native="updateTitleBrokerage"  :active="false" id="pills-brokerage"  href="#brokerage" ariaControls="pills-brokerage" role="tab" :ariaSelected="false" title="Brokerage" />
          <tab-nav-items liClass=" " v-on:click.native="updateTitle"  :active="false" id="pills-followers" href="#followers" ariaControls="pills-followers" role="tab" :ariaSelected="false" title="Followers" />
          <tab-nav-items liClass=" " v-on:click.native="updateTitle"  :active="false" id="pills-following"  href="#following" ariaControls="pills-following" role="tab" :ariaSelected="false" title="Following" />
        </tab-nav>
        <div class="tab-content">
          <tab-content-item :active="true" id="friends" aria-labelled-by="pills-friends">
            <ConnectionsCardStyle1 :items="friends">
              <template v-slot:content="{item}">
                <p class="mb-0">{{item.friend}}</p>
              </template>
              <template v-slot:actions="{item}">
                <b-link v-if="adminView || item.isFriend" class="btn btn-homeiz mr-2" role="button">
                  <i class="ri-close-line mr-1 font-size-16"></i> Unfriend
                </b-link>
                <b-link v-else class="btn btn-primary mr-2" role="button">
                  <i class="ri-add-line mr-1 text-white font-size-16"></i> Add Friend
                </b-link>
              </template>
            </ConnectionsCardStyle1>
          </tab-content-item>
          <tab-content-item :active="false" id="business" aria-labelled-by="pills-business">
            <ConnectionsCardStyle1 :items="business">
              <template v-slot:content="{item}">
                <p class="mb-0">{{item.friend}}</p>
              </template>
              <template v-slot:actions="{item}">
                <b-link v-if="adminView || item.isConnected" class="btn btn-homeiz mr-2" aria-expanded="true" role="button">
                  <i class="ri-close-line mr-1 font-size-16"></i> Disconnect
                </b-link>
                <b-link v-else class="btn btn-facebook mr-2" role="button">
                  <i class="ri-add-line mr-1 font-size-16"></i> Connect for business
                </b-link>
              </template>
            </ConnectionsCardStyle1>
          </tab-content-item>
          <tab-content-item :active="false" id="neighborhood" aria-labelled-by="pills-neighborhood">
            <ConnectionsCardStyle1 :items="neighborhood"/>
          </tab-content-item>
          <tab-content-item :active="false" id="brokerage" aria-labelled-by="pills-brokerage">
            <ConnectionsCardStyle1 :items="brokerage"/>
          </tab-content-item>
          <tab-content-item :active="false" id="followers" aria-labelled-by="pills-followers">
            <ConnectionsCardStyle1 :items="followers">
              <template v-slot:content="{item}">
                <b-badge v-if="item.isFollow" variant="secondary">Follows you</b-badge>
              </template>
              <template v-slot:actions="{item}">
                <b-link v-if="!item.isFollow" class="btn btn-primary mr-2" role="button">
                  <i class="ri-add-line text-white mr-1 font-size-16"></i> Follow
                </b-link>
                <b-link v-if="item.isFollow" class="btn btn-homeiz mr-2" role="button">
                    <i class="ri-close-line mr-1 font-size-16"></i> Unfollow
                </b-link>
              </template>
            </ConnectionsCardStyle1>
          </tab-content-item>
          <tab-content-item :active="false" id="following" aria-labelled-by="pills-following">
            <ConnectionsCardStyle1 :items="following">
              <template v-slot:content="{item}">
                <b-badge v-if="item.isFollow" variant="secondary">Follows you</b-badge>
              </template>
              <template v-slot:actions="{item}">
                <b-link v-if="!adminView && !item.isFollow" class="btn btn-primary mr-2" role="button">
                  <i class="ri-add-line text-white mr-1 font-size-16"></i> Follow
                </b-link>
                <b-link v-else  class="btn btn-homeiz mr-2" role="button">
                  <i class="ri-close-line mr-1 font-size-16"></i> Unfollow
                </b-link>
              </template>
            </ConnectionsCardStyle1>
          </tab-content-item>
        </div>
      </div>
    </template>
  </iq-card>
</template>

<script>
import ConnectionsCardStyle1 from '@/components/socialvue/connections/ConnectionsCardStyle1'
export default {
  name: 'ProfileConnectionsTab',
  components: { ConnectionsCardStyle1 },
  props: {
    adminView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      friends: [
        {
          img: require('@/assets/images/user/05.jpg'),
          name: 'Jaques Amole',
          friend: '40  friends',
          isFriend: true
        },
        {
          img: require('@/assets/images/user/06.jpg'),
          name: 'Lucy Tania',
          friend: '12  friends',
          isFriend: true
        },
        {
          img: require('@/assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0  friends'
        },
        {
          img: require('@/assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends'
        },
        {
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          friend: '15  friends',
          isFriend: true
        },
        {
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8  friends'
        },
        {
          img: require('@/assets/images/user/05.jpg'),
          name: 'Paul Misunday',
          friend: '6  friends'
        },
        {
          img: require('@/assets/images/user/13.jpg'),
          name: 'Reanne Carnation',
          friend: '12  friends'
        }
      ],
      business: [
        {
          img: require('@/assets/images/user/06.jpg'),
          name: 'Lucy Tania',
          friend: '12 mutual business connections',
          isConnected: true
        },
        {
          img: require('@/assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0 mutual business connections'
        },
        {
          img: require('@/assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3 mutual business connections'
        },
        {
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          friend: '15 mutual business connections'
        },
        {
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8 mutual business connections',
          isConnected: true
        }
      ],
      neighborhood: [
        {
          id: 1,
          img: require('@/assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0  friends'
        },
        {
          id: 2,
          img: require('@/assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends'
        },
        {
          id: 3,
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          friend: '15  friends'
        },
        {
          id: 4,
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8  friends'
        },
        {
          id: 5,
          img: require('@/assets/images/user/05.jpg'),
          name: 'Paul Misunday',
          friend: '6  friends'
        },
        {
          id: 6,
          img: require('@/assets/images/user/13.jpg'),
          name: 'Reanne Carnation',
          friend: '12  friends'
        }
      ],
      brokerage: [
        {
          id: 1,
          img: require('@/assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends'
        },
        {
          id: 2,
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          friend: '15  friends'
        },
        {
          id: 3,
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8  friends'
        },
        {
          id: 4,
          img: require('@/assets/images/user/05.jpg'),
          name: 'Paul Misunday',
          friend: '6  friends'
        }
      ],
      following: [
        {
          id: 1,
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          isFollow: false
        },
        {
          id: 2,
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8  friends',
          isFollow: false
        },
        {
          id: 3,
          img: require('@/assets/images/user/05.jpg'),
          name: 'Paul Misunday',
          friend: '6  friends',
          isFollow: true
        },
        {
          id: 4,
          img: require('@/assets/images/user/13.jpg'),
          name: 'Reanne Carnation',
          friend: '12  friends',
          isFollow: false
        }
      ],
      followers: [
        {
          id: 1,
          img: require('@/assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0  friends',
          isFollow: false
        },
        {
          id: 2,
          img: require('@/assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends',
          isFollow: true
        },
        {
          id: 3,
          img: require('@/assets/images/user/09.jpg'),
          name: 'Marsha Mello',
          friend: '15  friends',
          isFollow: false
        },
        {
          id: 4,
          img: require('@/assets/images/user/10.jpg'),
          name: 'Caire Innet',
          friend: '8  friends',
          isFollow: false
        }
      ],
      activeTab: 'Friends'
    }
  },
  computed: { },
  methods: {
    updateTitle: function (event) {
      this.activeTab = event.target.firstChild.data
    },
    updateTitleNeighbor: function (event) {
      this.activeTab = `${event.target.firstChild.data} - Los Angeles 90028`
    },
    updateTitleBrokerage: function (event) {
      this.activeTab = `${event.target.firstChild.data} - Re/Max Elite`
    }
  }
}
</script>

<style scoped>

</style>
