<template>
    <div :class="`right-sidebar-mini `+miniClass">
        <div class="right-sidebar-panel p-0">
            <div class="iq-card shadow-none">
                <div class="iq-card-body p-0">
                    <div class="media-height iq-chat-data-block active-block p-3">
                        <h5 class="text-capitalize text-center text-bold">{{ title }}</h5>
                        <hr>
                        <ul class="todo-task-list p-0 m-0">
                            <li class="pb-3" v-for="(item,index) in menuItems" :key="index">
                                <b-link :title="$t(item.name)" href="#"><i :class="item.icon" class="mr-2"></i>{{ $t(item.name) }}</b-link>
                            </li>
                        </ul>
                    </div>
                    <div class="right-sidebar-toggle bg-martin mt-3" @click="toggleMini">
                        <i class="ri-arrow-left-line side-left-icon"></i>
                        <i class="ri-arrow-right-line side-right-icon">
                          <span class="ml-3 d-inline-block">Close Menu</span></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileMenuItems from '@/FackApi/json/ProfileMenu'
export default {
  name: 'ProfileMenu',
  components: { },
  data () {
    return {
      miniClass: '',
      title: 'Profile',
      menuItems: ProfileMenuItems,
      search: ''
    }
  },
  methods: {
    toggleMini () {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar () {
      if (this.rightSideBarMini) {
        this.miniClass = 'right-sidebar'
      } else {
        this.miniClass = ''
      }
    },
    searchGroups () {
      this.$emit('on-search', this.search)
    }
  }
}
</script>

<style scoped>

</style>
