<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">Engage with {{ user.firstName }}</h4>
    </template>
    <template v-slot:body>
      <ul class="media-story m-0 p-0">
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Connect for business</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Add as friend</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Recommend</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Follow</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Send a referral</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Request face to face meeting</button>
        </li>
        <li class="d-flex mb-2 align-items-center justify-content-between">
          <button class="btn btn-facebook mb-3 w-100" type="button">Save as lead</button>
        </li>
      </ul>
    </template>
  </iq-card>
</template>

<script>
export default {
  name: 'EngageWidget',
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
