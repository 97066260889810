<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">Friends</h4>
    </template>
    <template v-slot:body>
      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
        <li class="col-md-4 col-6 pl-2 pr-0 pb-3" v-for="(friend,index) in friends" :key="index">
          <a href="#">
            <img :src="friend.img" alt="gallary-image" class="img-fluid rounded" /></a>
          <h6 class="mt-2">{{friend.name}}</h6>
        </li>
      </ul>
      <hr>
      <router-link :to="{name: 'social.firends'}" class="btn btn-homeiz d-block mt-3">See All</router-link>
    </template>
  </iq-card>
</template>

<script>
export default {
  name: 'FriendsWidget',
  data () {
    return {
      friends: [
        {
          img: require('@/assets/images/user/05.jpg'),
          name: 'Anna Rexia'
        },
        {
          img: require('@/assets/images/user/06.jpg'),
          name: 'Tara Zona'
        },
        {
          img: require('@/assets/images/user/07.jpg'),
          name: 'Polly Tech'
        },
        {
          img: require('@/assets/images/user/08.jpg'),
          name: 'Bill Emia'
        },
        {
          img: require('@/assets/images/user/09.jpg'),
          name: 'Moe Fugga'
        },
        {
          img: require('@/assets/images/user/10.jpg'),
          name: 'Hal Appeno '
        },
        {
          img: require('@/assets/images/user/09.jpg'),
          name: 'Zack Lee'
        },
        {
          img: require('@/assets/images/user/07.jpg'),
          name: 'Terry Aki'
        },
        {
          img: require('@/assets/images/user/08.jpg'),
          name: 'Greta Life'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
