<template>
  <b-col sm="12">
    <iq-card body-class=" profile-page p-0">
      <template v-slot:body>
        <div class="profile-header">
          <div class="cover-container">
            <b-img :src="profileImage" alt="profile-bg" rounded fluid class="w-100 profile-background" />
            <ul v-if="editable" class="d-flex profile-header--actions flex-wrap justify-end p-0 m-0">
              <li>
                <b-link :to="{ name: 'social.profile.my.edit' }" class="btn btn-facebook"><i class="ri-camera-3-line"></i>
                  Edit cover</b-link>
              </li>
            </ul>
          </div>
          <div class="user-detail text-center mb-3">
            <div class="profile-img">
              <img :src="avatar" alt="profile-img" class="avatar-130 img-fluid" />
            </div>
            <div class="profile-detail position-relative">
              <NotificationButton
                :tooltip="`Get notified about all of ${user.fullName}'s posts`"
                :subscribe="`You had subscribed to the ${user.fullName}'s posts.`"
                :unsubscribe="`You had unsubscribed to the ${user.fullName}'s posts.`"
              />
              <h3 class="text-bold">{{ user.fullName }}</h3>
              <router-link :to="{ name: 'social.profiles.view', params: { id: user.id } }">{{ user.nickname }}</router-link>
            </div>
            <hr class="mb-3" />
          </div>
          <div
            class="profile-info pl-2 pr-2 pb-2 d-flex flex-wrap align-items-center justify-content-between position-relative">
            <div class="social-info">
              <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                <li v-for="(i, index) in socialInfo" :key="index" class="text-center pl-3">
                  <h6>{{ i.name }}</h6>
                  <p class="mb-0"><b>{{ i.value }}</b></p>
                </li>
              </ul>
            </div>
            <div class="social-links">
              <div v-if="showActions"
                class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-end col-sm-12">
                <b-link :to="{ name: 'social.companies.view', params: { id: 1 } }" type="submit"
                  class="btn btn-facebook mb-2 ml-1"><i class="ri-building-line"></i> Company page</b-link>
                <b-link :to="{ name: 'social.profile.my.edit' }" v-if="editable" type="submit"
                  class="btn btn-transparent mb-2 ml-1"><i class="ri-pencil-line"></i> Edit profile</b-link>
                <button v-if="!editable" aria-label="Message" type="submit" class="btn btn-homeiz mb-2 ml-1"><i
                    class="ri-send-plane-line"></i> Send message</button>
                <b-dropdown v-if="!editable" id="dropdownMenuButton40" right variant="none" menu-class="p-0"
                  class="profile-tabs-actions mb-2 ml-1">
                  <template v-slot:button-content>
                    <b-link href="javascript:void(0)" class="text-secondary"><i class="mr-0 ri-more-2-line"></i></b-link>
                  </template>
                  <a href="javascript:void(0)" class="dropdown-item p-3">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="ri-user-add-line"></i></div>
                      <div class="data ml-2">
                        <h6>Follow {{ user.fullName }}</h6>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0)" class="dropdown-item p-3">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="ri-user-fill"></i></div>
                      <div class="data ml-2">
                        <h6>Send {{ user.fullName }} an invite</h6>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0)" class="dropdown-item p-3">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="ri-link"></i></div>
                      <div class="data ml-2">
                        <h6>Save {{ user.fullName }} as lead</h6>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0)" class="dropdown-item p-3">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="ri-close-circle-line"></i></div>
                      <div class="data ml-2">
                        <h6>Block {{ user.fullName }}</h6>
                      </div>
                    </div>
                  </a>
                </b-dropdown>
                <!--              <button type="submit" class="btn btn-facebook mb-2 ml-1">...</button>-->
              </div>
            </div>
          </div>
        </div>
      </template>
    </iq-card>
  </b-col>
</template>

<script>
import NotificationButton from '@/components/homeiz/notifications/NotificationButton'

export default {
  name: 'ProfileHeader',
  components: {
    NotificationButton
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultProfileImage: require('@/assets/images/page-img/profile-bg.jpg'),
      defaultAvatar: require('@/assets/images/user/04.jpg'),
      socialInfo: [
        {
          name: 'Post',
          value: 690
        },
        {
          name: 'Followers',
          value: 90
        },
        {
          name: 'Following',
          value: 100
        }
      ]
    }
  },
  computed: {
    profileImage () {
      return this.user.profileImage ? this.user.profileImage : this.defaultProfileImage
    },
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    }
  }
}
</script>

<style lang="scss" scoped></style>
