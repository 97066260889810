<template>
  <div :class="`right-sidebar-mini `+miniClass">
    <div class="right-sidebar-panel p-0">
      <div class="iq-card shadow-none">
        <div class="iq-card-body p-0">
          <div class="media-height iq-chat-data-block active-block p-3">
            <h5 class="text-capitalize text-center text-bold">{{ title }}</h5>
            <h5 class="text-center">{{ user.fullName }}</h5>
            <h6 class="text-center">{{ user.role }}</h6>
            <hr>
            <p>Hi I'm {{ user.firstName }}, I'm 36 and I founded and work as a Software Engineer at ZeppIT...</p>
            <hr>
            <ul class="todo-task-list p-0 m-0">
              <li class="pb-3" v-for="(item,index) in items" :key="index">
                <b-link href="#"><i :class="item.icon" class="mr-2"></i>{{ item.value }}</b-link>
              </li>
            </ul>
            <GmapMap
              :center="{ lat: 39.2217261, lng: -76.8662562 }"
              :zoom="11"
              :options="mapOptions"
              style="width: 100%; height: 200px"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="false"
                :draggable="false"
                @click="center=m.position"
              />
            </GmapMap>
          </div>
          <div class="right-sidebar-toggle bg-martin mt-3" @click="toggleMini">
            <i class="ri-arrow-left-line side-left-icon"></i>
            <i class="ri-arrow-right-line side-right-icon">
              <span class="ml-3 d-inline-block">Close Menu</span></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileDetails',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      miniClass: '',
      title: 'About',
      markers: [{
        position: {
          lat: 39.2217261,
          lng: -76.8662562
        }
      }],
      mapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false
      },
      items: [
        {
          title: 'Email',
          value: this.user.email,
          icon: 'ri-mail-line'
        },
        {
          title: 'Phone',
          value: '410.277.8855',
          icon: 'ri-phone-line'
        },
        {
          title: 'Country',
          value: 'United States Of America',
          icon: 'ri-map-pin-line'
        },
        {
          title: 'Web site business',
          value: 'zeppit.pro',
          icon: 'ri-focus-2-line'
        },
        {
          title: 'Web site personal',
          value: 'maschinoff.com',
          icon: 'ri-focus-line'
        },
        {
          title: 'Languages',
          value: 'English, Russian, Ukrainian, German',
          icon: 'ri-global-line'
        },
        {
          title: 'Hobby',
          value: 'Playing guitar',
          icon: 'ri-account-pin-circle-line'
        },
        {
          title: 'Work',
          value: 'Pilgrim Shoes',
          icon: 'ri-space-ship-line'
        }
      ]
    }
  },
  methods: {
    toggleMini () {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar () {
      if (this.rightSideBarMini) {
        this.miniClass = 'right-sidebar'
      } else {
        this.miniClass = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
