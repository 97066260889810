<template>
  <div class="d-flex">
    <div class="iq-card-header-toolbar d-flex align-items-start">
      <b-dropdown id="dropdownMenuButton40" right variant="erez007" toggle-class="pl-0 post-action-btn" menu-class="p-0">
        <template v-slot:button-content>
          <b-link href="javascript:void(0)" class="text-secondary">
            <i class="m-0 ri-more-line font-size-16"></i></b-link>
        </template>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-pencil-line"></i></div>
            <div class="data ml-2">
              <div>Edit post</div>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-save-2-line"></i></div>
            <div class="data ml-2">
              <div>Save post to saved items</div>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-save-line"></i></div>
            <div class="data ml-2">
              <div>Save post to saved leads</div>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-delete-bin-line"></i></div>
            <div class="data ml-2">
              <div>Delete post</div>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-close-circle-line"></i></div>
            <div class="data ml-2">
              <div>Hide post</div>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-user-unfollow-line"></i></div>
            <div class="data ml-2">
              <div>Report post</div>
            </div>
          </div>
        </a>
      </b-dropdown>
    </div>
    <div class="position-relative d-flex align-items-start justify-content-center">
      <b-button class="cursor-pointer hide-post ml-2" title="Hide" @click="hidePost">
        <i class="ri-close-line mr-0"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostActions',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  methods: {
    hidePost () {
      this.post.is_hidden = !this.post.is_hidden
    }
  }
}
</script>

<style>
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--iq-primary) !important;
}
</style>
