<template>
  <div>
    <div to="rightSidebar">
      <ProfileDetails :user="user" />
    </div>
    <b-row>
      <ProfileHeader :user="user" :show-actions="true"/>
      <ProfileTabs :user="user" :show-actions="true" />
      <b-col sm="4">
        <EngageWidget :user="user"/>
        <BusinessConnectionWidget/>
        <FriendsWidget/>
      </b-col>
      <b-col sm="8">
        <ProfilesTabsContent/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader'
import ProfileTabs from './ProfileTabs'
import ProfileDetails from './ProfileDetails'
import ProfilesTabsContent from './ProfilesTabsContent'
import FriendsWidget from '@/components/homeiz/widgets/FriendsWidget'
import BusinessConnectionWidget from '@/components/homeiz/widgets/BusinessConectionWidget'
import EngageWidget from '@/components/homeiz//widgets/EngageWidget'

export default {
  name: 'GuestProfile',
  components: {
    EngageWidget,
    BusinessConnectionWidget,
    FriendsWidget,
    ProfilesTabsContent,
    ProfileDetails,
    ProfileHeader,
    ProfileTabs
  },
  data () {
    return { }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
