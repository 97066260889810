<template>
  <b-row>
    <b-col v-for="(item,index) in photos" :key="index" md="6" lg="6" class="mb-3">
      <div class="user-images position-relative overflow-hidden">
        <a href="#">
          <img :src="item.img" class="img-fluid rounded" alt="Responsive image">
        </a>
        <div class="image-hover-data">
          <div class="product-elements-icon">
            <ul class="d-flex align-items-center m-0 p-0 list-inline">
              <li v-for="(item,index1) in item.otherInfo" :key="index1">
                <a href="#" class="pr-3 text-white"> {{item.value}} <i :class="item.class"></i> </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProfilePhotoTab',
  data () {
    return {
      photos: [
        {
          img: require('@/assets/images/page-img/51.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('@/assets/images/page-img/52.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
