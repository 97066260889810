<template>
  <div class="iq-card-body p-0">
    <div class="row">
      <div class="col-md-6 col-lg-12 mb-3" v-for="(item,index) in items" :key="index">
        <div class="iq-friendlist-block">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <a href="#">
                <img :src="item.img" alt="profile-img" class="img-fluid">
              </a>
              <div class="friend-info ml-3">
                <h5>{{item.name}}</h5>
                <slot name="content" v-bind:item="item">
                </slot>
              </div>
            </div>
            <!-- Buttons -->
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <slot name="actions" v-bind:item="item">
                <b-link :to="{ name: 'social.profiles.view', params: { id: item.id } }" class="btn btn-homeiz mr-2">
                  <i class="ri-eye-line mr-1 font-size-16"></i> View profile
                </b-link>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectionsCardStyle1',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
